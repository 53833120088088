<template>
    <v-card rounded>
        <v-toolbar flat height="35">
        <v-toolbar-title>Accounts</v-toolbar-title>
    <!--<v-spacer></v-spacer>
    <v-btn icon v-show="minimize" @click.prevent="miniminzeMaximize()">
      <v-icon>fas fa-expand-arrows-alt</v-icon>
    </v-btn>
    <v-btn icon v-show="!minimize" @click.prevent="miniminzeMaximize()">
      <v-icon>fas fa-compress-arrows-alt</v-icon>
    </v-btn>
    <v-btn icon>
      <v-icon>fas fa-sync</v-icon>
    </v-btn>
    <v-btn icon v-show="showhide" @click.prevent="showHide()">
      <v-icon>fas fa-chevron-up</v-icon>
    </v-btn>
    <v-btn icon v-show="!showhide" @click.prevent="showHide()">
      <v-icon>fas fa-chevron-down </v-icon>
    </v-btn>-->
        <!--<v-btn icon>
      <v-icon>fas fa-wrench</v-icon>
    </v-btn>-->
        <!--<v-btn icon>
      <v-icon>fas fa-times</v-icon>
    </v-btn>-->
        </v-toolbar>
        <!--<v-card v-show="showhide" :class="heightSetter" flat>-->
        <!--<v-label>Accounts</v-label>-->
        <v-card flat style="max-height: 205px; overflow: auto; ">
            <v-chip-group column
                          multiple>
                <account v-bind:accountData="account" v-for="account in itemsMinusDeleted" :key="account.id" class="minPad"></account>

                <v-chip v-if="itemsMinusDeleted.length !== 0" outlined large @click="$router.push({ path: '/dashboard/addaccount' })" class="minPad">
                    <v-icon>fas fa-plus</v-icon>
                    <v-container>
                        <v-layout row wrap>
                            <v-flex xs12 class="lessPad">Create Account</v-flex>
                        </v-layout>
                    </v-container>
                </v-chip>

            </v-chip-group>

            <noData v-if="itemsMinusDeleted.length === 0"></noData>
        </v-card>

            <!--</v-card>-->
    </v-card>
</template>

<script>
  import { accountManager } from '../../javascript/accountManager'

  export default {
        components: {
          account: () => import('./accountWidget/singleAccount.vue'),
          noData: () => import('../noData/noDataNoPad.vue')
    },
    data() {
      return {
        component: null,
        title: '',
        minimize: true,
        showhide: true,
        heightSetter: '',
        sizeSetter: '',
        items: [],
        itemsMinusDeleted: []
      }
    },
    methods: {
      miniminzeMaximize: function () {
        this.minimize = !this.minimize
      },
      showHide: function () {
        this.showhide = !this.showhide
      },
      setHeight: function () {
        if (this.type == 'account') {
          this.heightSetter = 'aHeight'
        }
        else {
          this.heightSetter = 'height'
        }
      },
      syncData: function () {

      }

    },
    //mounted() {
     
    //  if (this.type == 'account') {
    //    this.component = 'account'
    //    this.title = "Accounts"
    //  }
     

    //  //this.setHeight()
    //},
        beforeMount() {
            //this.items = JSON.parse(JSON.stringify(this.$store.state.Accounts))

            this.items = this.$store.state.Accounts

            ///Remove deleted items from items array
            this.itemsMinusDeleted = accountManager.removeDeletedAccountsFromItems(this.items)

        }
  }

</script>
<style scoped>

  .v-icon {
    font-size: 15px
  }

  .height {
    height: 200px
  }

  .aHeight {
    max-height: 90px
  }

  .fullSize {
    width: 100vw !important;
    height: 100vh !important;
  }

    .minPad {
        margin: 6px 6px !important;
}
</style>
